import React, { useState } from "react"
import PropTypes from "prop-types"
const LikeButton = (props) => {
  const [likes, setLikes] = useState(100);
  const [isLiked, setIsLiked] = useState(props.isLiked);

  const handleClick = () => {
    if (isLiked) {
      setLikes(likes - 1);
    } else {
      setLikes(likes + 1);
    }
    setIsLiked(!isLiked);
  };

  return (
    <button className={`like-button ${isLiked && 'liked'}`} onClick={handleClick} >
      <span className="likes-counter">{`Like | ${likes}`}</span>
    </button >
  );
}

LikeButton.propTypes = {
  likableType: PropTypes.node,
  likableId: PropTypes.node
};
export default LikeButton
